<template>
  <div class="auth-wrapper auth-v1 px-2">
    <b-overlay :show="loading">
      <div class="auth-inner py-2">
        <!-- Forgot Password v1 -->
        <b-card class="mb-0">
          <b-link class="brand-logo">
            <!-- logo -->

            <h1 class="brand-text text-primary ml-1">ADMIN ELP</h1>
          </b-link>

          <b-card-title class="mb-1"> Lupa Password? 🔒 </b-card-title>
          <b-card-text class="mb-2">
            Masukan Email Kamu yang Telah Didaftarkan untuk Mengatur Ulang Kata
            Sandi
          </b-card-text>

          <!-- form -->
          <validation-observer ref="forgotPassword">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="submit"
            >
              <!-- email -->
              <b-form-group label="Email" label-for="forgot-password-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="forgot-password-email"
                    placeholder="Masukkan email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button variant="primary" block type="submit"> Kirim </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <b-link :to="{ name: 'admin-login' }">
              <feather-icon icon="ChevronLeftIcon" /> Kembali login
            </b-link>
          </b-card-text>
        </b-card>
        <!-- /Forgot Password v1 -->
        <b-modal
          id="modal-scoped"
          ref="my-modal"
          centered
          title="Using Component Methods"
        >
          <template #modal-header="{ close }">
            <h5>Verifikasi email</h5>
          </template>

          <template #default="{ hide }">
            <h4>{{ message }}</h4>
          </template>

          <template #modal-footer="{ ok, cancel, hide }">
            <b-button size="lg" block variant="info" @click="visitEmail()">
              Lihat Email
            </b-button>
          </template>
        </b-modal>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BOverlay,
  BCard,
  BLink,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BModal,
} from "bootstrap-vue";
import { required, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    VuexyLogo,
    BOverlay,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BModal,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: "",
      // validation
      required,
      email,
      loading: false,
      message: "",
    };
  },
  methods: {
    visitEmail() {
      window.location.href = "https://mail.google.com/mail/u/0/#inbox";
    },
    submit() {
      this.$refs.forgotPassword.validate().then((success) => {
        if (success) {
          this.loading = true;
          const payload = {
            email: this.userEmail,
          };
          this.$store
            .dispatch("auth/forgotPassword", payload)
            .then((res) => {
              this.userEmail = "";
              this.loading = false;
              this.message = res.data.message;
              this.$refs["my-modal"].show();
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Peringatan`,
                  icon: "AlertCircleIcon",
                  variant: "warning",
                  text: this.message,
                },
              });
            })
            .catch((error) => {
              this.loading = false;
              this.$refs.loginForm.setErrors(error);
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error `,
                  icon: "XIcon",
                  variant: "danger",
                  text: error.response.data.message,
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
